<template>
    <div style="height: 100%">
        <!-- <div class="ad-wrap">
        <div class="ad-image-bg"></div>
    </div> -->
        <TopBanner :showTab="showTab">
            <div class="search_div_father">
                <common-header :title="`${showTabTitle}港箱货跟踪`">
                    <div
                        v-if="tabLayout === 'vertical'"
                        slot="header"
                        style="margin-bottom: 8px"
                    >
                        <TabBarGroup
                            :value="showTab"
                            @change="showTabClick"
                        ></TabBarGroup>
                    </div>
                    <div class="input_div_father" slot="content">
                        <div
                            v-if="tabLayout === 'horizontal'"
                            style="margin-right: 20px"
                        >
                            <TabBarGroup
                                :value="showTab"
                                @change="showTabClick"
                            ></TabBarGroup>
                        </div>
                        <div class="input_div" v-if="showTab === 'QD'">
                            <el-select
                                placeholder="请选择场站"
                                v-model="webName"
                                filterable
                            >
                                <el-option
                                    v-for="item in webNameList"
                                    :key="item"
                                    :label="luhaitongFilter(item)"
                                    :value="item"
                                ></el-option>
                            </el-select>
                        </div>
                        <!-- 暂时注释 TODO -->
                        <!-- <div class="input_div" v-if="showTab === 'XM'">
                            <el-select
                                placeholder="请选择码头"
                                v-model="XMWharf"
                                filterable
                            >
                                <el-option
                                    v-for="item in XMWharfList"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                            </el-select>
                        </div> -->
                        <div class="input_div">
                            <el-autocomplete
                                clearable
                                @clear="blurForBug()"
                                class="input-with-select"
                                v-model="vesselsName"
                                @input="checkInputVesselename()"
                                @change="checkInputVesselename2(vesselsName)"
                                :fetch-suggestions="shipSearch"
                                placeholder="请输入船名"
                                :trigger-on-focus="false"
                                @select="handleShipSelect"
                                style="font-size: 12px"
                            >
                                <template slot-scope="{ item }">
                                    <!-- <el-tooltip style="font-size:12px" class="item" effect="dark" :content="item.value" placement="bottom" open-delay="600"> -->
                                    <div
                                        style="
                                            font-size: 12px;
                                            display: block;
                                            overflow: hidden;
                                            word-break: keep-all;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        "
                                        class="name"
                                    >
                                        {{ item.value }}
                                    </div>
                                    <!-- </el-tooltip> -->
                                </template>
                            </el-autocomplete>
                            <el-input
                                clearable
                                placeholder="请输入航次"
                                v-model="voyage"
                                @input="checkInputVoyage()"
                                class="query-input_center"
                            ></el-input>
                            <el-input
                                placeholder="请输入提单号（必填）"
                                v-model="blno"
                                maxlength="30"
                                @input="checkInputBlno"
                                @keyup.enter.native="getAllBoxWatch()"
                                class="query-input_right"
                            ></el-input>
                            <div class="model_opt_flag_wrap">
                                <el-radio-group
                                    v-model="modelOptflag"
                                    size="small"
                                >
                                    <el-radio label="0">整柜</el-radio>
                                    <el-radio label="1">拼票</el-radio>
                                </el-radio-group>
                            </div>
                            <el-popover
                                placement="top"
                                trigger="manual"
                                v-model="repeatPopVisible"
                            >
                                <div class="repeat_pop_content">
                                    {{ repeatPopTitle }}
                                </div>
                                <template #reference>
                                    <el-tooltip
                                        effect="dark"
                                        placement="top"
                                        v-if="showTab === 'SH'"
                                    >
                                        <template #content>
                                            <div class="watch-tooltip-message">
                                                当前订阅为上海提单，每票消耗1火币
                                            </div>
                                        </template>
                                        <el-button
                                            :loading="single_add_loading"
                                            class="query-input_btn"
                                            @click="getAllBoxWatch()"
                                            size="small"
                                        >
                                            添加跟踪 ({{ showTabTitle }})
                                        </el-button>
                                    </el-tooltip>
                                    <el-button
                                        v-else
                                        :loading="single_add_loading"
                                        class="query-input_btn"
                                        @click="getAllBoxWatch()"
                                        size="small"
                                    >
                                        添加跟踪 ({{ showTabTitle }})
                                    </el-button>
                                </template>
                            </el-popover>
                        </div>
                        <el-tooltip
                            effect="dark"
                            placement="top"
                            v-if="showTab === 'SH'"
                        >
                            <template #content>
                                <div class="watch-tooltip-message">
                                    当前订阅为上海提单，每票消耗1火币
                                </div>
                            </template>
                            <el-button
                                :loading="multi_add_loading"
                                type="primary"
                                class="query-input_btn multi"
                                @click="uploadHandle()"
                            >
                                批量跟踪 ({{ showTabTitle }})
                            </el-button>
                        </el-tooltip>
                        <el-button
                            v-else
                            :loading="multi_add_loading"
                            type="primary"
                            class="query-input_btn multi"
                            @click="uploadHandle()"
                        >
                            批量跟踪 ({{ showTabTitle }})
                        </el-button>
                    </div>
                    <div slot="footer" class="header_footer">
                        <img src="@/assets/icon/warning.svg" />
                        <div>
                            物流跟踪及订阅，数据每半个小时更新一次
                            <span v-if="showTab === 'NB'">
                                ，需要实时查询，请用
                                <span class="go_boxquery" @click="goBoxQuery">
                                    物流可视化
                                </span>
                                页面，谢谢！
                            </span>
                        </div>
                    </div>
                </common-header>
            </div>
        </TopBanner>

        <div class="data_div">
            <div class="data_search_div">
                <el-popover
                    placement="top"
                    trigger="manual"
                    v-model="popoverVisible"
                    :content="popoverContent"
                >
                    <div slot="reference" class="search_input_div">
                        <el-dropdown trigger="click" size="small">
                            <span
                                class="el-dropdown-link"
                                style="
                                    display: flex;
                                    margin-left: 5px;
                                    width: 8rem;
                                    align-items: center;
                                "
                            >
                                筛选状态
                                <i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-radio-group
                                        v-model="searchCustomflag"
                                        @change="selectTypeList"
                                    >
                                        <el-radio label="">
                                            海放全部状态
                                        </el-radio>
                                        <el-radio label="1">
                                            海关已放行
                                        </el-radio>
                                        <el-radio label="0">
                                            海关未放行
                                        </el-radio>
                                    </el-radio-group>
                                </el-dropdown-item>
                                <el-dropdown-item
                                    divided
                                    v-if="['NB', 'SH', 'XM'].includes(showTab)"
                                >
                                    <el-radio-group
                                        v-model="searchNppstatus"
                                        @change="selectTypeList"
                                    >
                                        <el-radio label="">
                                            码头全部状态
                                        </el-radio>
                                        <el-radio label="1">
                                            码头已放行
                                        </el-radio>
                                        <el-radio label="0">
                                            码头未放行
                                        </el-radio>
                                    </el-radio-group>
                                </el-dropdown-item>
                                <el-dropdown-item
                                    divided
                                    v-if="
                                        ['NB', 'SH', 'QD', 'XM'].includes(
                                            showTab,
                                        )
                                    "
                                >
                                    <el-radio-group
                                        v-model="searchCtnapplybeginFlag"
                                        @change="selectTypeList"
                                    >
                                        <el-radio label="">
                                            开港全部状态
                                        </el-radio>
                                        <el-radio label="1">
                                            有开港时间
                                        </el-radio>
                                        <el-radio label="0">
                                            无开港时间
                                        </el-radio>
                                    </el-radio-group>
                                </el-dropdown-item>
                                <el-dropdown-item
                                    divided
                                    v-if="['NB', 'XM'].includes(showTab)"
                                >
                                    <el-radio-group
                                        v-model="searchPortcloseFlag"
                                        @change="selectTypeList"
                                    >
                                        <el-radio label="">
                                            截单全部状态
                                        </el-radio>
                                        <el-radio label="1">
                                            有截单时间
                                        </el-radio>
                                        <el-radio label="0">
                                            无截单时间
                                        </el-radio>
                                    </el-radio-group>
                                </el-dropdown-item>
                                <el-dropdown-item
                                    divided
                                    v-if="['NB', 'XM'].includes(showTab)"
                                >
                                    <el-radio-group
                                        v-model="searchCtnapplyendFlag"
                                        @change="selectTypeList"
                                    >
                                        <el-radio label="">
                                            截港全部状态
                                        </el-radio>
                                        <el-radio label="1">
                                            有截港时间
                                        </el-radio>
                                        <el-radio label="0">
                                            无截港时间
                                        </el-radio>
                                    </el-radio-group>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-dropdown
                            trigger="click"
                            placement="bottom"
                            size="small"
                        >
                            <span
                                class="el-dropdown-link"
                                style="
                                    display: flex;
                                    width: 8rem;
                                    align-items: center;
                                "
                            >
                                截单/截港
                                <i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-radio
                                        label="0"
                                        v-model="portcloseFlag"
                                        @change="selectTypeList"
                                        class="radio_width"
                                    >
                                        显示全部
                                    </el-radio>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-radio
                                        label="1"
                                        v-model="portcloseFlag"
                                        @change="selectTypeList"
                                        class="radio_width"
                                    >
                                        临近截关期(提前{{
                                            $store.state.portclosetime
                                        }}小时)
                                    </el-radio>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-radio
                                        label="2"
                                        v-model="portcloseFlag"
                                        @change="selectTypeList"
                                        class="radio_width"
                                    >
                                        临近截港期(提前{{
                                            $store.state.ctnapplyendtime
                                        }}小时)
                                    </el-radio>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>

                        <el-autocomplete
                            clearable
                            size="small"
                            @clear="blurForBug()"
                            class="inline-input"
                            v-model="searchVesselenameVoyage"
                            @input="checkSearchVesselenameVoyage()"
                            :fetch-suggestions="querySearch"
                            placeholder="输入船名/航次"
                            :trigger-on-focus="false"
                            popper-class="vessel-voyage-popper"
                            @select="handleSelect"
                            @change="handleInputClear"
                            @keyup.enter.native="selectInfo()"
                        ></el-autocomplete>
                        <el-autocomplete
                            clearable
                            size="small"
                            @clear="blurForBug()"
                            class="inline-input input-with-select query-input_right"
                            v-model="searchBlno"
                            @input="checkSearchBlno()"
                            :fetch-suggestions="querySearchBlno"
                            placeholder="输入提单号搜索"
                            :trigger-on-focus="false"
                            @change="handleInputClear"
                            @select="handleSelectBlno"
                            @keyup.enter.native="selectInfo()"
                        ></el-autocomplete>
                        <el-input
                            v-model="searchRemark"
                            placeholder="输入备注搜索"
                            size="small"
                            class="remark-search-input"
                            clearable
                            @change="handleInputClear"
                            @keyup.enter.native="handleRemarkSearch"
                        ></el-input>
                        <el-button
                            size="small"
                            slot="append"
                            icon="el-icon-search"
                            style="margin-left: 2px"
                            @click="selectInfo()"
                        ></el-button>
                    </div>
                </el-popover>
                <div class="search_input_div" style="margin-left: 8px">
                    <el-select
                        v-model="timeType"
                        size="small"
                        clearable
                        placeholder="请选择"
                        style="width: 130px"
                    >
                        <el-option
                            v-for="item in timeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                    <el-date-picker
                        v-model="searchtime"
                        size="small"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        @change="handleInputClear"
                    ></el-date-picker>
                    <el-button
                        size="small"
                        slot="append"
                        icon="el-icon-search"
                        @click="getBoxListByPage"
                    ></el-button>
                </div>
                <el-button
                    class="refresh_btn flex_margin_item"
                    size="small"
                    type="success"
                    plain
                    @click="refreshBoxList()"
                >
                    重新载入
                </el-button>
                <el-button
                    class="flex_margin_item"
                    type="danger"
                    plain
                    @click="deleteBoxList()"
                    size="small"
                >
                    批量删除
                </el-button>
                <el-popover
                    placement="top"
                    trigger="click"
                    v-model="downloadPopVisible"
                    class="flex_margin_item"
                >
                    <div class="download_btn_div">
                        <el-button
                            @click="getExcelModel(1)"
                            class="download_btn"
                            type="primary"
                            plain
                        >
                            1、船名航次在同一格
                        </el-button>
                        <el-button
                            @click="getExcelModel(2)"
                            class="download_btn"
                            type="primary"
                            plain
                        >
                            2、船名航次在不同格
                        </el-button>
                    </div>
                    <el-button slot="reference">下载模板</el-button>
                </el-popover>
                <el-tooltip placement="top" class="flex_margin_item">
                    <div slot="content">
                        <div
                            slot="header"
                            class="clearfix"
                            style="font-size: 14px"
                        >
                            <span>
                                出现以下情形之一的会自动挪入到历史记录，不再进行后续的订阅更新。
                                <br />
                                若是挪入有误，可以进行还原操作。
                            </span>
                        </div>
                        <div
                            v-for="mess in listData"
                            :key="mess"
                            class="text item"
                            style="font-size: 12px; margin: 6px 0 0 4px"
                        >
                            {{ mess }}
                        </div>
                    </div>
                    <el-button class="refresh_btn" @click="goHistory">
                        历史记录
                    </el-button>
                </el-tooltip>
                <column-set
                    :table-list="tableList"
                    page-key="logistics"
                    useSystemKey
                    :custom-style="{ marginTop: 0 }"
                    :reset-data="{
                        NB: RESETTABLE.resetTableNB,
                        SH: RESETTABLE.sResetTable,
                        QD: RESETTABLE.qdResetTable,
                        TJ: RESETTABLE.tjResetTable,
                        XM: RESETTABLE.xmResetTable,
                    }"
                    :save-mutation="{
                        NB: 'table/editTableListNB',
                        SH: 'table/editTableListSH',
                        QD: 'table/editTableListQD',
                        TJ: 'table/editTableListTJ',
                        XM: 'table/editTableListXM',
                    }"
                    :reset-mutation="{
                        NB: 'table/resetTableListNB',
                        SH: 'table/resetTableListSH',
                        QD: 'table/resetTableListQD',
                        TJ: 'table/resetTableListTJ',
                        XM: 'table/resetTableListXM',
                    }"
                ></column-set>
                <el-button
                    class="refresh_btn flex_margin_item"
                    style="margin-right: 0"
                    @click="showSettingDialog()"
                >
                    微信推送设置
                </el-button>
                <el-button
                    class="refresh_btn flex_margin_item"
                    style="margin-right: 0"
                    @click="handleChangeFixHeader"
                >
                    {{ fixTableHeader ? "关闭固定表头" : "开启固定表头" }}
                </el-button>
            </div>
            <div class="table_div">
                <el-table
                    border
                    stripe
                    v-loading="loading"
                    ref="multipleTable"
                    :key="tableKey"
                    :cell-class-name="cellClass"
                    :cell-style="cellCss"
                    :data="boxList"
                    style="width: 100%"
                    :max-height="tableMaxHeight"
                    @selection-change="handleSelectionChange"
                    @header-dragend="handleHeaderChange"
                    :default-sort="{ prop: 'date', order: 'descending' }"
                    :header-cell-style="{
                        background: '#eef1f6',
                        color: '#606266',
                    }"
                >
                    <el-table-column
                        type="selection"
                        :selectable="selectable"
                        width="30"
                        fixed="left"
                    ></el-table-column>
                    <el-table-column type="index" width="30" fixed="left">
                        <template slot-scope="scope">
                            <p
                                :style="{
                                    minHeight:
                                        boxList.length < 5 ? '40px' : 'auto',
                                    lineHeight:
                                        boxList.length < 5 ? '40px' : 'auto',
                                }"
                            >
                                {{ scope.row.index }}
                            </p>
                        </template>
                    </el-table-column>
                    <template v-for="(item, index) in tableList">
                        <el-table-column
                            v-if="item.show && isFixedColumn(item.prop)"
                            :prop="item.prop"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                <template v-if="item.prop === 'area'">
                                    <PlaceShow :name="showTab" />
                                </template>
                                <template v-if="item.prop === 'billNo'">
                                    <template slot="header">
                                        <el-row>
                                            <span style="margin-right: 5px">
                                                提单号
                                            </span>

                                            <!-- <table-header-tooltip content="">
                                                    <div>
                                                        提单号超过7天无数据更新，将会自动删除并
                                                        <br />
                                                        移交至历史记录，如需订阅，请重新添加
                                                    </div>
                                                </table-header-tooltip> -->
                                        </el-row>
                                    </template>
                                    <el-tooltip
                                        :disabled="
                                            scope.row.changeVessels !== 'T'
                                        "
                                        class="item"
                                        effect="dark"
                                        content="该提单号出现过换船"
                                        placement="top"
                                    >
                                        <div class="blno-col-wrap">
                                            <div class="blno-left">
                                                <span
                                                    v-if="
                                                        scope.row.sonList &&
                                                        scope.row.sonList
                                                            .length &&
                                                        showList.findIndex(
                                                            (item) =>
                                                                item ==
                                                                scope.row.blno,
                                                        ) == -1
                                                    "
                                                    class="addshow_class"
                                                    style="
                                                        color: blue;
                                                        cursor: pointer;
                                                    "
                                                    @click="
                                                        showAllList(
                                                            scope.row.blno,
                                                        )
                                                    "
                                                >
                                                    +
                                                </span>
                                                <span
                                                    v-if="
                                                        scope.row.sonList &&
                                                        scope.row.sonList
                                                            .length &&
                                                        showList.findIndex(
                                                            (item) =>
                                                                item ==
                                                                scope.row.blno,
                                                        ) != -1
                                                    "
                                                    class="reduceshow_class"
                                                    style="
                                                        color: blue;
                                                        cursor: pointer;
                                                    "
                                                    @click="
                                                        showOneList(
                                                            scope.row.blno,
                                                        )
                                                    "
                                                >
                                                    -
                                                </span>
                                                <span
                                                    v-if="
                                                        scope.row.psType &&
                                                        scope.row.psType != '1'
                                                    "
                                                    style="width: 9px"
                                                ></span>
                                                <img
                                                    v-if="
                                                        (scope.row.psType &&
                                                            scope.row.psType !=
                                                                '1') ||
                                                        (scope.row.sonList &&
                                                            scope.row.sonList
                                                                .length)
                                                    "
                                                    src="@/assets/img/ping.png"
                                                    style="width: 12px"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="blno-center">
                                                <span
                                                    style="
                                                        white-space: nowrap;
                                                        flex-shrink: 0;
                                                    "
                                                >
                                                    {{ scope.row.blno }}
                                                </span>
                                                <img
                                                    src="@/assets/icon/copy.svg"
                                                    v-clipboard:copy="
                                                        scope.row.blno
                                                    "
                                                    v-clipboard:success="
                                                        copySucc
                                                    "
                                                />
                                            </div>
                                            <div class="blno-right">
                                                <span
                                                    v-if="
                                                        scope.row.sonCount !=
                                                            '0' &&
                                                        scope.row.sonCount !=
                                                            '1' &&
                                                        showList.findIndex(
                                                            (item) =>
                                                                item ==
                                                                scope.row.blno,
                                                        ) == -1
                                                    "
                                                    class="together_num_class"
                                                    style="color: blue"
                                                >
                                                    {{ scope.row.sonCount }}
                                                </span>
                                                <span
                                                    v-if="
                                                        (scope.row.psType &&
                                                            scope.row.psType !=
                                                                '1') ||
                                                        (scope.row.sonList &&
                                                            scope.row.sonList
                                                                .length &&
                                                            showList.findIndex(
                                                                (item) =>
                                                                    item ==
                                                                    scope.row
                                                                        .blno,
                                                            ) != -1)
                                                    "
                                                    class="together_num_class"
                                                    style="color: blue"
                                                >
                                                    {{ mixCount(scope.row) }}
                                                </span>
                                            </div>
                                        </div>
                                    </el-tooltip>
                                </template>
                                <template v-if="item.prop === 'shipInfo'">
                                    <span
                                        v-if="
                                            !scope.row.vesselsName &&
                                            !scope.row.voyage &&
                                            !scope.row.vvFlag &&
                                            scope.row.sonCount
                                        "
                                        @click="addVoyage(scope.row)"
                                        class="table_link_text_bold"
                                    >
                                        添加船名/航次
                                    </span>
                                    <div
                                        class="shipInfo-col-wrap"
                                        v-if="
                                            scope.row.vesselsName ||
                                            scope.row.voyage
                                        "
                                    >
                                        <span>
                                            <i
                                                @click="addVoyage(scope.row)"
                                                class="el-icon-edit"
                                            ></i>
                                            {{
                                                `${scope.row.vesselsName} / ${scope.row.voyage}`
                                            }}
                                        </span>
                                        <el-dropdown>
                                            <img src="@/assets/icon/copy.svg" />
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item
                                                    v-clipboard:copy="
                                                        scope.row.vesselsName
                                                    "
                                                    v-clipboard:success="
                                                        copySucc
                                                    "
                                                >
                                                    复制船名
                                                </el-dropdown-item>
                                                <el-dropdown-item
                                                    v-clipboard:copy="
                                                        scope.row.voyage
                                                    "
                                                    v-clipboard:success="
                                                        copySucc
                                                    "
                                                >
                                                    复制航次
                                                </el-dropdown-item>
                                                <el-dropdown-item
                                                    v-clipboard:copy="
                                                        `${scope.row.vesselsName}/${scope.row.voyage}`
                                                    "
                                                    v-clipboard:success="
                                                        copySucc
                                                    "
                                                >
                                                    复制全部
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                    <!-- </el-tooltip> -->
                                </template>
                                <template v-if="item.prop === 'shipPosition'">
                                    <div
                                        v-if="
                                            scope.row.vesselsName &&
                                            scope.row.voyage
                                        "
                                        @click="goShipMap(scope.row)"
                                        style="
                                            cursor: pointer;
                                            display: flex;
                                            align-items: center;
                                            flex-direction: column;
                                        "
                                    >
                                        <el-image
                                            class="class_ship"
                                            :src="ship"
                                        ></el-image>
                                    </div>
                                </template>
                                <template v-if="item.prop === 'blnoMain'">
                                    <div>
                                        <span
                                            v-if="!scope.row.blnoMain"
                                            @click="addBlnoMain(scope.row)"
                                            class="table_link_text_bold"
                                        >
                                            添加舱单主单号
                                        </span>
                                        <div v-else style="padding: 0 4px">
                                            <span>
                                                <i
                                                    @click="
                                                        addBlnoMain(scope.row)
                                                    "
                                                    class="el-icon-edit"
                                                ></i>
                                                {{ `${scope.row.blnoMain}` }}
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="
                                item.show &&
                                item.prop !== 'boxCusmov' &&
                                !isFixedColumn(item.prop)
                            "
                            show-overflow-tooltip
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :resizable="item.resizable"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                <span
                                    v-if="
                                        [
                                            'boxArrivalManifest',
                                            'boxArrivalCount',
                                        ].includes(item.prop)
                                    "
                                    class="table_link_text_bold p_gold"
                                >
                                    {{ scope.row[item.prop] }}
                                </span>
                                <span v-else-if="item.prop === 'wharf'">
                                    <span>
                                        {{
                                            wharfCodeToStr(scope.row[item.prop])
                                        }}
                                    </span>
                                </span>
                                <span v-else-if="item.prop === 'wharfCode'">
                                    <YGTWharfEdit
                                        :row="scope.row"
                                        :data="scope.row[item.prop]"
                                        :nameOptions="{
                                            wharfName: 'wharf',
                                            vesselsName: 'vesselsName',
                                            voyageName: 'voyage',
                                        }"
                                        @confirm="getBoxListByPage"
                                    ></YGTWharfEdit>
                                </span>
                                <RemarkCell
                                    v-else-if="item.prop === 'remark'"
                                    :row-data="scope.row"
                                    @edit="addRemark"
                                ></RemarkCell>
                                <el-tooltip
                                    v-else-if="
                                        item.prop === 'nppStatus' &&
                                        ['NB', 'XM'].includes(showTab)
                                    "
                                    class="item"
                                    effect="dark"
                                    :disabled="!scope.row.wharfRemark"
                                    :content="scope.row.wharfRemark"
                                    placement="top"
                                >
                                    <span
                                        :class="[
                                            {
                                                p_green:
                                                    scope.row[item.prop] ===
                                                    'Y',
                                            },
                                            {
                                                p_red:
                                                    scope.row[item.prop] ===
                                                    'N',
                                            },
                                        ]"
                                    >
                                        {{
                                            passFlag2String(
                                                scope.row[item.prop],
                                                showTab === "XM",
                                            )
                                        }}
                                    </span>
                                </el-tooltip>
                                <el-tooltip
                                    class="item"
                                    effect="light"
                                    :disabled="
                                        !scope.row.changeLogs ||
                                        scope.row.changeLogs.length <= 1
                                    "
                                    v-else-if="
                                        item.prop === 'nppStatus' &&
                                        ['SH', 'TJ'].includes(showTab)
                                    "
                                >
                                    <el-badge
                                        :hidden="
                                            !scope.row.changeLogs ||
                                            scope.row.changeLogs.length <= 1
                                        "
                                        :value="
                                            scope.row.changeLogs &&
                                            scope.row.changeLogs.length > 1
                                                ? scope.row.changeLogs.length
                                                : 0
                                        "
                                        class="is-dot"
                                    >
                                        <span
                                            :class="[
                                                {
                                                    p_green:
                                                        scope.row[item.prop] ===
                                                            'Y' &&
                                                        (!scope.row
                                                            .changeLogs ||
                                                            scope.row.changeLogs
                                                                .length <= 1),
                                                },
                                                {
                                                    p_blue:
                                                        scope.row[item.prop] ===
                                                            'Y' &&
                                                        scope.row.changeLogs &&
                                                        scope.row.changeLogs
                                                            .length > 1,
                                                },
                                            ]"
                                        >
                                            {{
                                                passFlag2String(
                                                    scope.row[item.prop],
                                                    true,
                                                )
                                            }}
                                        </span>
                                    </el-badge>

                                    <template slot="content">
                                        <div style="padding: 0 20px">
                                            <h3>码放变更记录：</h3>
                                            <el-timeline>
                                                <el-timeline-item
                                                    v-for="(
                                                        activity, index
                                                    ) in scope.row.changeLogs"
                                                    :key="index"
                                                    :timestamp="
                                                        activity.createTime
                                                    "
                                                >
                                                    <div>
                                                        <div
                                                            class="changeLog-item-value"
                                                        >
                                                            {{
                                                                passFlag2String(
                                                                    activity.nppStatus,
                                                                    true,
                                                                )
                                                            }}
                                                        </div>
                                                        <div
                                                            v-if="
                                                                activity.nppStatus &&
                                                                activity.nppStatus !==
                                                                    'Y' &&
                                                                activity.descs
                                                            "
                                                            class="changeLog-item-desc"
                                                        >
                                                            ({{
                                                                activity.descs
                                                            }})
                                                        </div>
                                                    </div>
                                                </el-timeline-item>
                                            </el-timeline>
                                        </div>
                                    </template>
                                </el-tooltip>
                                <empty-takeout
                                    v-else-if="item.prop === 'emptyOutCount'"
                                    :rowData="scope.row"
                                ></empty-takeout>
                                <span
                                    v-else-if="item.prop === 'errorValue'"
                                    class="icon_wrapper"
                                >
                                    <div v-if="showTab === 'NB'">
                                        <el-image
                                            v-if="scope.row[item.prop] == 0"
                                            :src="icon_green"
                                            class="passIcon"
                                        ></el-image>
                                        <p v-else>
                                            {{
                                                scope.row[item.prop]
                                                    ? `${scope.row[item.prop]}%`
                                                    : ""
                                            }}
                                        </p>
                                    </div>
                                </span>
                                <!-- 上海,厦门进港舱单比对 -->
                                <span
                                    v-else-if="item.prop === 'errorValueCheck'"
                                    class="icon_wrapper"
                                >
                                    <el-image
                                        v-if="scope.row.errorValue === 'true'"
                                        :src="icon_green"
                                        class="passIcon"
                                    ></el-image>
                                    <el-popover
                                        v-else-if="
                                            scope.row.errorValue !== null
                                        "
                                        :content="scope.row.errorValue"
                                        trigger="hover"
                                    >
                                        <el-image
                                            slot="reference"
                                            :src="icon_red"
                                            class="passIcon"
                                        ></el-image>
                                    </el-popover>
                                </span>
                                <truck-no-add
                                    v-else-if="item.prop === 'boxPosition'"
                                    :rowData="scope.row"
                                    :cellProp="item.prop"
                                    @change="getBoxListByPage"
                                    @login="login"
                                ></truck-no-add>
                                <span v-else-if="item.prop.includes('Time')">
                                    {{ formatTime(scope.row[item.prop]) }}
                                </span>
                                <span
                                    v-else-if="
                                        item.prop === 'preFlag' ||
                                        item.prop === 'cpcodeFlag' ||
                                        item.prop === 'ifcsumFlag' ||
                                        item.prop === 'sldFlag' ||
                                        item.prop === 'customFlag'
                                    "
                                    class="icon_wrapper"
                                >
                                    <el-image
                                        v-if="scope.row[item.prop] === 'Y'"
                                        :src="icon_green"
                                        class="passIcon"
                                    ></el-image>
                                    <el-image
                                        v-if="scope.row[item.prop] === 'N'"
                                        :src="icon_red"
                                        class="failIcon"
                                    ></el-image>
                                </span>
                                <template v-else-if="item.prop === 'webname'">
                                    <div>
                                        <i
                                            @click="
                                                handleUpdateWebName(scope.row)
                                            "
                                            class="el-icon-edit"
                                        ></i>
                                        {{
                                            luhaitongFilter(
                                                scope.row[item.prop],
                                            )
                                        }}
                                    </div>
                                </template>
                                <span v-else>
                                    {{
                                        luhaitongFilter(scope.row[item.prop]) ||
                                        "-"
                                    }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-if="item.prop === 'boxCusmov' && item.show"
                            show-overflow-tooltip
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :resizable="item.resizable"
                        >
                            <template slot="header" slot-scope="scope">
                                <el-row
                                    type="flex"
                                    align="middle"
                                    justify="center"
                                >
                                    <span>海关查验</span>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        placement="top"
                                    >
                                        <template slot="content">
                                            <span>
                                                如需查看海关查验详细流程，请
                                            </span>
                                            <a
                                                :href="boxCusmovDoc"
                                                download
                                                style="color: #409eff"
                                            >
                                                点击下载
                                            </a>
                                        </template>
                                        <el-image
                                            :src="boxCusmovHeadIcon"
                                            class="tIcon_style"
                                        ></el-image>
                                    </el-tooltip>
                                </el-row>
                            </template>
                            <template slot-scope="scope">
                                <template v-if="scope.row[item.prop]">
                                    <el-row
                                        type="flex"
                                        align="middle"
                                        justify="center"
                                    >
                                        <span>{{ scope.row[item.prop] }}</span>
                                        <el-image
                                            :src="tootipIcon"
                                            class="tIcon_style"
                                            @click="openWindow(boxCusmovDoc)"
                                        ></el-image>
                                    </el-row>
                                </template>
                                <template v-else>
                                    {{ "-" }}
                                </template>
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column
                        fixed="right"
                        prop="address"
                        label="操作"
                        :formatter="formatter"
                        width="180"
                    >
                        <template slot-scope="scope">
                            <div class="table_control_div">
                                <p
                                    v-if="scope.row.psType == '1'"
                                    class="toTop"
                                    @click="whetherTop(scope.row)"
                                >
                                    {{
                                        scope.row.topType == "0"
                                            ? "置顶"
                                            : "取消"
                                    }}
                                </p>
                                <p v-else class="toTop">-</p>
                                <p
                                    class="detail"
                                    @click="showDetailDialog(scope.row)"
                                >
                                    详情
                                </p>
                                <!-- <p
                                    class="journal"
                                    @click="showJournalDialog(scope.row.blno)"
                                >
                                    日志
                                </p> -->
                                <p
                                    v-if="scope.row.psType == '1'"
                                    class="delete"
                                    @click="deleteBoxItem(scope.row)"
                                >
                                    删除
                                </p>
                                <p
                                    v-else
                                    style="width: 40px; color: rgb(213, 13, 0)"
                                >
                                    -
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pagination_div">
                <el-pagination
                    background
                    :hide-on-single-page="pageTotal < 10"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="sizes,prev, pager, next"
                    :total="pageTotal"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size.sync="pageSize"
                    :current-page.sync="pagePosition"
                ></el-pagination>
            </div>
        </div>
        <ShipDialog
            :visible.sync="optStatus"
            class="assemble_dialog"
            size="small"
            title="请输入拼票数"
            width="400px"
        >
            <el-input
                placeholder="请输入拼票数（2-99）"
                v-model="putTogetherNum"
            ></el-input>
            <div class="model_opt_left_div">
                <el-popover placement="top" trigger="manual">
                    <div slot="reference" class="model_choose_div">
                        <el-dropdown trigger="click" placement="bottom">
                            <span class="el-dropdown-link model_opt_btn">
                                {{ chooseModelflag == "0" ? "默认" : "自定义" }}
                                <i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-radio
                                        label="0"
                                        v-model="chooseModelflag"
                                        @click.native="customizeNum = ''"
                                        class="radio_width"
                                    >
                                        默认
                                    </el-radio>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-radio
                                        label="1"
                                        v-model="chooseModelflag"
                                        @click.native="customizeNum = ''"
                                        class="radio_width"
                                    >
                                        自定义
                                    </el-radio>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <span
                            v-if="chooseModelflag === '0'"
                            class="span-class"
                            style="flex-grow: 1"
                        >
                            默认从A开始自动排序
                        </span>
                        <span v-if="chooseModelflag === '1'">
                            <el-input
                                placeholder="示例: B-D, G, O-Q"
                                v-model="customizeNum"
                                @input="checkInputAlpha"
                                class="input-together-choose"
                                style="flex-grow: 1"
                            ></el-input>
                        </span>
                    </div>
                </el-popover>
            </div>
            <DialogFooter
                @confirm="checkPutTogetherNum"
                @cancel="optStatus = false"
                :top="30"
            ></DialogFooter>
        </ShipDialog>
        <setting-dialog
            v-if="settingDialogVisible"
            ref="SettingDialog"
            :showTab="showTab"
        ></setting-dialog>
        <login-dialog
            v-if="loginDialogVisible"
            ref="LoginDialog"
        ></login-dialog>
        <!-- 弹窗, 删除 -->
        <delete-box-dialog
            :showTab="showTab"
            v-if="deleteDialogVisible"
            ref="DeleteBoxDialog"
            @refreshDataList="getBoxListByPage()"
        ></delete-box-dialog>
        <!-- 弹窗, 详情 -->
        <detail-dialog
            v-if="datailDialogVisible"
            ref="DetailDialog"
            @addRemark="addRemark()"
            :rows="rows"
            :showTab="showTab"
            @refreshDataList="getBoxListByPage()"
        ></detail-dialog>
        <!-- 弹窗, 日志 -->
        <journal-dialog
            v-if="journalDialogVisible"
            ref="JournalDialog"
        ></journal-dialog>
        <!-- 弹窗, 修改备注 -->
        <remark-box-dialog
            :showTab="showTab"
            v-if="remarkDialogVisible"
            ref="RemarkBoxDialog"
            @refreshDataList="getBoxListByPage()"
            @getData="getData()"
        ></remark-box-dialog>
        <!-- 弹窗, 上传文件 -->
        <upload
            v-if="uploadVisible"
            ref="upload"
            :showTab="showTab"
            @refreshDataList="getBoxListByPage()"
        ></upload>
        <voyage-change-dialog
            :showTab="showTab"
            v-if="voyageDialogVisible"
            ref="VoyageChangeDialog"
            @refreshDataList="getBoxListByPage"
        ></voyage-change-dialog>
        <!-- 上海,青岛，天津分票的弹窗 -->
        <SHSplitOptDialog
            ref="shsplitRef"
            @confirm="handleConfirmSHSplit"
            :showTab="showTab"
        />
        <!-- 青岛,天津拼箱主提单修改弹窗 -->
        <QDSplitBlnoMainDialog
            @confirm="getBoxListByPage"
            ref="BlnoMainDialog"
            :showTab="showTab"
            v-if="blnoMainDialogVisible"
        />
        <!-- 青岛场站修改弹窗 -->
        <QDWebNameDialog
            @confirm="getBoxListByPage"
            ref="WebNameDialog"
            v-if="webNameDialogVisible"
        ></QDWebNameDialog>
        <!-- 拼票提单号选择 -->
        <ChildBlnoDialog
            ref="childBlnoDialogRef"
            @confirm="BoxesWatch"
        ></ChildBlnoDialog>
    </div>
</template>

<script>
import { takeoutTable } from "@/data";
import CommonHeader from "@/components/common/CommonHeader";
import {
    setRightForrmat,
    setRightForrmatSpace,
    checkRightFormat,
    setRightForrmatSpace3,
} from "@/utils/validate";
import RemarkCell from "@/components/common/RemarkCell";
import ColumnSet from "@/components/common/ColumnSet";
import TableHeaderTooltip from "@/components/common/TableHeaderTooltip";
import VoyageChangeDialog from "@/components/ship/VoyageChangeDialog";
import Upload from "@/components/common/oss-upload";
import TabBarImage from "@/components/common/TabBarImage";
import YGTWharfEdit from "@/components/common/YGTWharfEdit";
import LoginDialog from "@/components/register/WechatLoginDialog";
import DeleteBoxDialog from "@/components/ship/DeleteBoxDialog";
import DetailDialog from "@/components/ship/DetailDialog";
import RemarkBoxDialog from "@/components/ship/RemarkDialog";
import ShipDialog from "@/components/layout/ShipDialog";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import SettingDialog from "./components/PushSetting";
import TruckNoAdd from "./components/TruckNoAdd";
import EmptyTakeout from "./components/EmptyTakeout";
import JournalDialog from "./components/JournalDialog";
import SHSplitOptDialog from "./components/SHSplitOptDialog.vue";
import QDSplitBlnoMainDialog from "./components/QDSplitBlnoMainDialog.vue";
import QDWebNameDialog from "./components/QDWebNameDialog.vue";
import ChildBlnoDialog from "./components/ChildBlnoDialog.vue";
import { getToken, getCompanyId, getRole } from "@/utils/auth";
import { mapGetters } from "vuex";
import { orderBoxTicket } from "@/api/tracking";
import { searchShip, updateRemarkAndTruck } from "@/api/boxQuery";
import { queryXMWharfList } from "@/api/xiamenLogistics";
import {
    apiCallback,
    objectFilterEmpty,
    passFlag2String,
    wharfCodeToStr,
    getCheck986Str,
    msToDate,
    freeBalanceLackTip,
    passFlag2StringXM,
} from "../../utils";
import { getUserAccountInfo, getWarfList, getNameByCode } from "@/utils";
import PlaceShow from "@/components/common/PlaceShow";
import TopBanner from "@/components/layout/TopBanner";
import TabBarGroup from "@/components/common/TabBarGroup.vue";
import { logisticsRequest, luhaitongFilter } from "./logistics";
import { SHORT_CUTS } from "@/utils/dataList";
import { queryQDstations } from "@/api/qingdaoLogistics";
import { shipRequest } from "../ShipSubscrib/ship";
import { logisticsTableList } from "./tableList";
export default {
    data() {
        return {
            showTab: "NB",
            rows: "",
            wharfCodeToStr,
            passFlag2String,
            luhaitongFilter,
            passFlag2StringXM,
            takeoutTable,
            msToDate,
            icon_green: require("@/assets/img/green.png"),
            icon_red: require("@/assets/img/red.png"),
            ship: require("@/assets/img/chuan1.png"),
            tootipIcon: require("@/assets/img/boxCusmovTootipIcon.png"),
            boxCusmovHeadIcon: require("@/assets/img/boxCusmovHeadIcon.png"),
            boxCusmovDoc:
                "https://web.nbhuojian.com/static/fangcangwang/%E6%9F%A5%E9%AA%8C%E7%A7%8D%E7%B1%BB%E8%AF%B4%E6%98%8E.doc",
            multi_add_loading: false, //批量添加
            single_add_loading: false, //单独添加
            settingDialogVisible: false,
            voyageDialogVisible: false,
            loginDialogVisible: false,
            deleteDialogVisible: false,
            datailDialogVisible: false,
            journalDialogVisible: false,
            remarkDialogVisible: false,
            blnoMainDialogVisible: false, //青岛拼箱主提单号弹窗
            webNameDialogVisible: false, // 青岛场站修改弹窗
            popoverVisible: false,
            uploadVisible: false,
            downloadPopVisible: false,
            detailcontent: false,
            popoverContent: "",
            loading: false,
            test: "测试信息",
            loginInterval: "",
            vesselsName: "",
            putTogetherNum: "",
            shopCompanyValue: "",
            voyage: "",
            blno: "",
            searchBlno: "", //搜索框提单号
            searchVesselename: "", //搜索框船名
            searchVesselenameVoyage: "", //搜索框船名航次
            searchVoyage: "", //搜索框航次
            searchNppstatus: "", //筛选框码头放行
            searchCustomflag: "", //筛选框海放
            searchCtnapplybeginFlag: "", //筛选框开港
            searchCtnapplyendFlag: "", //筛选框截港
            searchPortcloseFlag: "", //筛选框截单
            searchtime: "", //搜索框时间
            searchRemark: "", // 筛选框备注
            totalBoxList: [], //存放原始数组用于筛选
            totalshipList: [], //存放原始船名航次用于筛选
            totalVoyageList: [], //存放已订阅船名航次用于筛选
            totalBlnoList: [], //存放已订阅提单号用于筛选
            boxList: [], //展示用的列表数据
            multipleSelection: [], //勾选中的列表
            carList: [], //集卡定位车号
            repeatPopTitle: "",
            repeatPopVisible: false,
            // 表格key
            tableKey: 1,
            pageTotal: 0,
            pageTotalSH: 0,
            pagePosition: 1,
            startRow: 1,
            nppOptions: [
                {
                    value: "",
                    label: "码头状态",
                },
                {
                    value: "Y",
                    label: "码头已放行",
                },
                {
                    value: "N",
                    label: "码头未放行",
                },
            ],
            customOptions: [
                {
                    value: "",
                    label: "海放状态",
                },
                {
                    value: "Y",
                    label: "海关已放行",
                },
                {
                    value: "N",
                    label: "海关未放行",
                },
            ],
            isNbSystem: true,
            // 上海船司列表
            shopCompanyList: [],
            searchShopCompanyValue: "",
            portcloseFlag: "0", //截关截港筛选，0：全部，1：截关，2：截港
            is_portclose: false, //临近截关期
            is_ctnapplyend: false, //临近截港期
            divlineIndex: 0, //置顶和不置顶的区分线
            optStatus: false, //拼单弹窗关闭
            modelOptflag: "0", // 0:整柜 1：拼单
            chooseModelflag: "0", // 拼票方式 0：默认 1：自定义
            customizeNum: "", //自定义拼单数
            customizeRes: [], //自定义ACSII
            showList: [], //需展开的提单号
            listData: [
                "1.主动删除",
                "2.离港后1个月",
                "3.添加订阅后1个月内无任何数据更新",
                "4.多方原因未完结60天",
                "5.未输入船名,并且7日内查不出任何数据",
            ],
            searchtime: "", //搜索框时间
            timeType: "", //日期筛选类型
            shipCompany: "", //船司
            fixTableHeader: false, // 是否固定表头
            pickerOptions: {
                shortcuts: [...SHORT_CUTS],
            },
            // 青岛场站列表
            webNameList: [],
            // 当前青岛选中的场站
            webName: "",
            // 厦门码头列表
            XMWharfList: [],
            // 当前厦门选中的列表
            XMWharf: "",
            // changeVesselName: false
        };
    },
    components: {
        SettingDialog,
        LoginDialog,
        DeleteBoxDialog,
        DetailDialog,
        JournalDialog,
        Upload,
        RemarkBoxDialog,
        VoyageChangeDialog,
        CommonHeader,
        TableHeaderTooltip,
        ColumnSet,
        SHSplitOptDialog,
        TabBarImage,
        PlaceShow,
        QDSplitBlnoMainDialog,
        TruckNoAdd,
        EmptyTakeout,
        QDWebNameDialog,
        YGTWharfEdit,
        TopBanner,
        ChildBlnoDialog,
        TabBarGroup,
        ShipDialog,
        DialogFooter,
        RemarkCell
    },
    created() {
        const { tab } = this.$route.query;
        if (tab) {
            this.showTab = tab.toUpperCase(tab);
        } else {
            this.showTab = localStorage.getItem("showTab") || this.showTab;
        }
    },
    mounted() {
        this.Init();
        // this.showTab = localStorage.getItem("showTab") || this.showTab;
    },
    computed: {
        ...mapGetters({
            pageSizeFromStore: "pageSize",
        }),
        tableMaxHeight() {
            return this.fixTableHeader ? window.innerHeight * 0.57 : "auto";
        },
        tabLayout() {
            return this.$store.state.other.screenWidth < 1610
                ? "vertical"
                : "horizontal";
        },
        pageSize: {
            get() {
                return this.pageSizeFromStore;
            },
            set(val) {
                this.$store.commit("table/editPageSize", val);
            },
        },
        tableList: {
            get() {
                return logisticsTableList({
                    tab: this.showTab,
                    type: 1,
                });
            },
            set(val) {
                return logisticsTableList({
                    tab: this.showTab,
                    type: 2,
                    data: val,
                });
            },
        },

        timeOptions() {
            return logisticsTableList({
                tab: this.showTab,
                type: 4,
            });
        },

        showTabTitle() {
            return getNameByCode(this.showTab);
        },
        curResetTable() {
            return logisticsTableList({
                tab: this.showTab,
                type: 3,
            });
        },
        areaColumn() {
            const column =
                this.tableList.find((item) => item.prop === "area") ||
                this.curResetTable.find((item) => item.prop === "area");
            return column;
        },
    },
    watch: {
        tableList: {
            handler(val) {
                this.tableKey += 1;
            },
            deep: true,
        },
        modelOptflag(newVal) {
            if (this.showTab === "NB") {
                this.optStatus = newVal === "1";
            } else if (["QD", "SH", "TJ", "XM"].includes(this.showTab)) {
                // 上海,青岛分票的弹窗
                newVal === "1" && this.$refs.shsplitRef.open();
            }
        },
    },
    methods: {
        copySucc() {
            this.$message.success("复制成功");
        },
        resetSearch() {
            this.searchRemark = "";
            this.searchBlno = "";
            this.searchVesselename = "";
            this.searchVesselenameVoyage = "";
            this.searchVoyage = "";
            this.searchNppstatus = "";
            this.searchCustomflag = "";
            this.searchCtnapplybeginFlag = "";
            this.searchCtnapplyendFlag = "";
            this.searchPortcloseFlag = "";
            this.portcloseFlag = "0";
            this.is_portclose = false;
            this.is_ctnapplyend = false;
            this.pagePosition = 1;
            this.timeType = "";
            this.searchtime = "";
        },
        handleChangeFixHeader() {
            if (this.fixTableHeader === true) {
                this.$confirm(
                    "关闭固定表头需要刷新页面，请确认您的数据已保存！",
                    "提示",
                    {
                        confirmButtonText: "立即关闭",
                        cancelButtonText: "取消",
                        type: "warning",
                    },
                ).then(() => {
                    this.fixTableHeader = !this.fixTableHeader;
                    localStorage.setItem(
                        "fixTableHeader_logistics",
                        this.fixTableHeader,
                    );
                    window.location.reload();
                });
            } else {
                this.fixTableHeader = !this.fixTableHeader;
                localStorage.setItem(
                    "fixTableHeader_logistics",
                    this.fixTableHeader,
                );
            }
        },
        isFixedColumn(key) {
            const keyArr = [
                "area",
                "billNo",
                "shipInfo",
                "shipPosition",
                "blnoMain",
            ];
            return keyArr.some((item) => item === key);
        },
        showTabClick(val) {
            if (this.loading) {
                this.$message.error("请等待数据加载完成再重试，请勿频繁切换");
                return;
            }
            if (val === this.showTab) {
                return;
            }
            this.showTab = val;
            this.boxList = [];
            this.resetSearch();
            this.Init();
            localStorage.setItem("showTab", val);
            this.$store.commit("other/editSystemKey", val);
        },
        Init() {
            this.checkLogin();
            this.getTableListData();
            const storageFixHeader =
                localStorage.getItem("fixTableHeader_logistics") === "true";
            this.fixTableHeader = storageFixHeader;
        },
        // 更新当前tableList
        getTableListData() {
            var newlen = 0;
            const curTableList = logisticsTableList({
                tab: this.showTab,
                type: 1,
            });
            for (var i = 0; i < curTableList.length; i++) {
                if (curTableList[i].label) {
                    newlen += curTableList[i].label.length;
                }
            }
            // 判断是否是宁波港决定渲染列表数据
            const restTable = logisticsTableList({
                tab: this.showTab,
                type: 3,
            });
            var oldlen = 0;
            for (var i = 0; i < restTable.length; i++) {
                if (restTable[i].label) {
                    oldlen += restTable[i].label.length;
                }
            }
            if (newlen !== oldlen) {
                this.tableList = curTableList;
            } else {
                this.resetTable();
            }
        },
        resetTable() {
            this.tableKey = 1;
            this.tableList = this.curResetTable;
        },
        // 显示隐藏切换 && 保存表格配置
        saveTableColumns() {
            // setStorage 封装了 localStorage
            logisticsTableList({
                tab: this.showTab,
                type: 2,
                data: this.tableList,
            });
        },
        // 筛选框清空
        handleInputClear(val) {
            if (!val) {
                this.timeType = "";
                this.pagePosition = 1;
                this.$nextTick(() => {
                    this.getBoxListByPage();
                });
            }
        },
        // 拼票弹窗取消
        handleAssembleDialogCancel() {
            this.optStatus = false;
        },
        //修改分页的pagesize
        handleSizeChange() {
            this.pagePosition = 1;
            this.$nextTick(() => {
                this.getBoxListByPage();
            });
        },
        //分页
        handleCurrentChange(val) {
            this.pagePosition = val;
            this.getBoxListByPage();
        },
        //登录，显示二维码弹窗
        login() {
            this.loginDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.LoginDialog.init();
            });
        },
        //定时执行
        checkLogin() {
            var that = this;
            this.loginInterval = setInterval(function () {
                that.checkToken();
            }, 10);
        },

        // 青岛获取场站
        getWebName() {
            queryQDstations().then((res) => {
                const data = apiCallback(res);
                this.webNameList = data;
            });
        },

        // 厦门获取码头
        getWharfList() {
            queryXMWharfList().then((res) => {
                const data = apiCallback(res);
                this.XMWharfList = data;
            });
        },

        async checkToken() {
            if (getToken()) {
                clearInterval(this.loginInterval);
                // 分页箱单
                this.getBoxListByPage();
                // 获取船名航次
                this.getVoyageList();
                // 获取提单
                this.getBlnoList();
                if (this.showTab === "QD") {
                    // 获取场站
                    this.getWebName();
                }
                if (this.showTab === "XM") {
                    // 获取码头
                    this.getWharfList();
                }
            } else {
                clearInterval(this.loginInterval);
            }
        },
        //选中的列表数据
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //自定义单元格宽度
        handleHeaderChange(newWidth, oldWidth, column, event) {
            this.tableList.forEach((item) => {
                if (item.label == column.label) {
                    item.width = newWidth;
                }
            });
            this.$store.commit("table/editTableListNB", this.tableList);
        },
        formatter(row, column) {
            return row.address;
        },
        //重置popver
        resetPopover() {
            this.popoverVisible = false;
            this.popoverContent = "";
        },
        //从列表中模糊匹配
        selectInfo() {
            if (this.searchRemark) {
                // 如果有备注则不需要校验其他筛选项格式
                this.pagePosition = 1;
                this.getBoxListByPage();
                return;
            }
            if (!this.searchBlno && !this.searchVesselenameVoyage) {
                this.popoverVisible = true;
                this.popoverContent = "请输入关键字";
                setTimeout(this.resetPopover, 2000);
                return;
            }
            if (
                this.searchBlno.length >= 4 ||
                this.searchVesselenameVoyage.length >= 4
            ) {
                if (this.searchVesselenameVoyage) {
                    const list = this.searchVesselenameVoyage.split("/");
                    if (list.length >= 2) {
                        const { vesseleName, voyage } =
                            this.formatVesselenameVoyage(list);
                        this.searchVesselename = vesseleName;
                        this.searchVoyage = voyage;
                        this.pagePosition = 1;
                        this.getBoxListByPage();
                    } else {
                        this.$message.error("请同时输入船名和航次");
                    }
                } else {
                    this.pagePosition = 1;
                    this.getBoxListByPage();
                }
            } else {
                this.popoverVisible = true;
                this.popoverContent = "请输入长度至少为4位的关键字";
                setTimeout(this.resetPopover, 2000);
                return;
            }
        },
        // 处理船名航次
        formatVesselenameVoyage(list) {
            var vesseleName = "";
            list.forEach((item, index) => {
                if (index == list.length - 1) {
                    return;
                } else if (index == list.length - 2) {
                    vesseleName = vesseleName + item;
                } else {
                    vesseleName = vesseleName + item + "/";
                }
            });
            return list.length === 2
                ? {
                      vesseleName,
                      voyage: list[list.length - 1],
                  }
                : {};
        },
        //重新载入符合状态的列表
        selectTypeList() {
            this.pagePosition = 1;
            this.getBoxListByPage();
        },
        //重置搜索条件，重新载入列表
        refreshBoxList() {
            this.resetSearch();
            this.getBoxListByPage();
        },
        //检测搜索关键字格式
        checkSearchBlno() {
            this.searchBlno = setRightForrmat(this.searchBlno);
        },
        checkInputAlpha() {
            this.customizeNum = checkRightFormat(this.customizeNum);
        },
        checkSearchVesselename() {
            this.searchVesselename = setRightForrmatSpace(
                this.searchVesselename,
            );
        },
        toggleShowList() {
            let checkShowList = [];
            this.totalBoxList.forEach((item) => {
                this.showList.forEach((item2) => {
                    if (item2 == item.blno) {
                        checkShowList.push(item2);
                    }
                });
            });
            this.showList = checkShowList;
            this.dealList(this.totalBoxList);
        },
        // 拼票展示全部
        showAllList(blno) {
            if (!this.showList.includes((item) => item.blno)) {
                this.showList.push(blno);
                this.toggleShowList();
            }
        },
        // 拼票收起
        showOneList(blno) {
            let index = this.showList.findIndex((item) => item == blno);
            if (~index) {
                this.showList.splice(index, 1);
                this.toggleShowList();
            }
        },
        // 判断拼单是否是2-99之间的整数
        checkNum() {
            return (
                this.putTogetherNum &&
                Number.isInteger(this.putTogetherNum) &&
                this.putTogetherNum > 1 &&
                this.putTogetherNum < 100
            );
        },
        //判断拼单输入是否正确
        checkPutTogetherNum() {
            let numRe = /^\+?[1-9][0-9]*$/;
            if (
                this.putTogetherNum == "" ||
                !numRe.test(this.putTogetherNum) ||
                this.putTogetherNum >= 100 ||
                this.putTogetherNum == 1
            ) {
                this.$message.error("请输入2—99的正整数");
            } else {
                if (this.chooseModelflag === "1") {
                    if (this.customizeNum) {
                        let customAscii = this.checktype(this.customizeNum);
                        if (
                            customAscii.length == parseInt(this.putTogetherNum)
                        ) {
                            this.customizeRes = customAscii;
                            this.optStatus = false;
                        } else {
                            if (customAscii.length == 0) {
                                this.$message.error("请输入正确的自定义格式");
                                return;
                            } else {
                                this.$message.error(
                                    "自定义字母总数" +
                                        String(customAscii.length) +
                                        "和拼票数" +
                                        this.putTogetherNum +
                                        "不符",
                                );
                                return;
                            }
                        }
                    } else {
                        this.$message.error("未按要求输入自定义字母");
                        return;
                    }
                } else {
                    this.optStatus = false;
                }
                this.getAllBoxWatch();
            }
        },
        getAllBoxWatch() {
            if (getToken()) {
                if (!this.blno) {
                    this.$message.warning("请输入提单号");
                    return;
                }
                if (this.showTab === "QD" && !this.webName) {
                    this.$message.warning("请先选择场站");
                    return;
                }
                // 暂时注释 TODO
                // if (this.showTab === "XM" && !this.XMWharf) {
                //     this.$message.warning("请先选择码头");
                //     return;
                // }

                if (
                    (this.vesselsName || this.voyage) &&
                    (!this.vesselsName || !this.voyage)
                ) {
                    this.$message.warning("请同时输入船名和航次");
                    return;
                }
                if (
                    ["QD", "SH", "TJ", "XM"].includes(this.showTab) &&
                    this.modelOptflag === "1"
                ) {
                    this.handleConfirmSHSplit(this.$refs.shsplitRef.textarea);
                    return;
                }
                if (this.modelOptflag === "1" && this.putTogetherNum == "") {
                    this.$message.warning("请输入拼票信息");
                    this.optStatus = true;
                    return;
                }
                if (this.modelOptflag === "1") {
                    if (this.chooseModelflag === "1") {
                        var togetherNum = this.customizeRes.map((index1) => {
                            return index1 - 64;
                        });
                    } else {
                        var togetherNum = [
                            ...new Array(parseInt(this.putTogetherNum)).keys(),
                        ];
                        togetherNum = togetherNum.map((index2) => {
                            return index2 + 1;
                        });
                    }
                } else {
                    var togetherNum = [];
                }

                var TogetherBlno = this.getTogetherBlno(this.blno, togetherNum);
                this.BoxesWatch(TogetherBlno);
            } else {
                this.login();
            }
        },
        getTogetherBlno(blon, toNum) {
            var ToBlno = [];
            if (this.modelOptflag == "1") {
                if (toNum) {
                    toNum.forEach((item) => {
                        let s = "";
                        item = parseInt(item);
                        while (item > 0) {
                            let m = item % 26;
                            m = m === 0 ? (m = 26) : m;
                            s = String.fromCharCode(64 + parseInt(m)) + s;
                            item = (item - m) / 26;
                        }
                        var singleBlon = blon + s;
                        ToBlno.push(singleBlon);
                    });
                }
            } else {
                ToBlno.push(blon);
            }
            return ToBlno;
        },
        checktype(val) {
            var temp = val.split(",");
            var res = [];
            temp.forEach((item) => {
                var data = item.split("-");
                res.push(data);
            });
            var str = [];
            res.forEach((item) => {
                if (item.length == 2) {
                    if (item[1].length == 1) {
                        var len =
                            item[1].charCodeAt() - item[0].charCodeAt() + 1;
                        // console.log(len)
                        for (var i = 0; i < len; i++) {
                            str.push(item[0].charCodeAt() + i);
                        }
                    } else if (item[0].length == 1 && item[1].length == 2) {
                        var len =
                            item[1][1].charCodeAt() -
                            64 +
                            (item[1][0].charCodeAt() -
                                item[0].charCodeAt() +
                                1) *
                                26;

                        for (var i = 0; i < len; i++) {
                            str.push(item[0].charCodeAt() + i);
                        }
                    } else if (item[0].length == 2 && item[1].length == 2) {
                        var len =
                            item[1][1].charCodeAt() -
                            item[0][1].charCodeAt() +
                            1 +
                            (item[1][0].charCodeAt() -
                                item[0][0].charCodeAt()) *
                                26;
                        // console.log(len)
                        for (var i = 0; i < len; i++) {
                            str.push(
                                (item[0][0].charCodeAt() - 64) * 26 +
                                    item[0][1].charCodeAt() +
                                    i,
                            );
                        }
                    }
                    // str += item
                } else if (item.length == 1) {
                    if (item[0].length == 1) {
                        // console.log(item)
                        str.push(item[0].charCodeAt());
                    } else if (item[0].length == 2) {
                        // console.log(item)
                        str.push(
                            (item[0][0].charCodeAt() - 64) * 26 +
                                item[0][1].charCodeAt(),
                        );
                    }
                } else {
                    str = [];
                }
            });
            return str;
        },

        checkSearchVesselenameVoyage() {
            this.searchVesselenameVoyage = setRightForrmatSpace3(
                this.searchVesselenameVoyage,
            );
        },
        checkSearchVoyage() {
            this.searchVoyage = setRightForrmat(this.searchVoyage);
        },
        //全部船名航次搜索框带输入建议
        shipSearch(queryString, cb) {
            var restaurants = this.totalshipList;
            var results = queryString
                ? restaurants.filter(this.shipFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        //船名航次搜索框带输入建议
        querySearch(queryString, cb) {
            var restaurants = this.totalVoyageList;
            var results = queryString
                ? restaurants.filter(this.createFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        shipFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value.indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.vesselsName
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1 ||
                    restaurant.voyage
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        //订阅框船名航次搜索框选择了输入建议
        handleShipSelect(item) {
            var selectShip = item.value.split("/");
            this.vesselsName = selectShip[0];
            this.voyage = selectShip[1];
        },
        //船名航次搜索框选择了输入建议
        handleSelect(item) {
            // console.log(item);
            this.searchVesselename = item.vesselsName;
            this.searchVoyage = item.voyage;
        },
        //提单号搜索框带输入建议
        querySearchBlno(queryString, cb) {
            var restaurants = this.totalBlnoList;
            var results = queryString
                ? restaurants.filter(this.createFilterBlno(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilterBlno(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toUpperCase()
                        .indexOf(queryString.toUpperCase()) != -1
                );
            };
        },
        //提单号搜索框选择了输入建议
        handleSelectBlno(item) {
            // console.log(item);
            this.searchVesselename = item.value;
        },
        handleRemarkSearch() {
            this.pagePosition = 1;
            this.getBoxListByPage();
        },
        //检测订阅跟踪输入的关键字格式
        checkInputBlno() {
            this.blno = setRightForrmat(this.blno);
        },
        checkInputVesselename() {
            if (this.vesselsName) {
                this.vesselsName = this.setRightForrmatSpace2(this.vesselsName);
            }
            if (this.vesselsName.length >= 4) {
                var that = this;
                searchShip({ vesselsName: this.vesselsName })
                    .then(({ data }) => {
                        that.totalshipList = [];
                        data.data &&
                            data.data.forEach((item) => {
                                this.totalshipList.push({
                                    value: `${item.s}/${item.v}`,
                                });
                            });
                    })
                    .catch((err) => {
                        this.totalshipList = [];
                        this.$message.error(err);
                    });
            } else if (this.vesselsName.length < 4) {
                this.totalshipList = [];
            }
        },
        checkInputVesselename2(val) {
            if (val) {
                var len = val.length - 1;
                if (val[len] == "." || val[len] == "/") {
                    this.vesselsName = val.substring(0, len);
                }
            }
        },
        checkInputVoyage() {
            if (this.voyage) {
                this.voyage = this.setRightForrmat(this.voyage);
            }
        },
        //判断输入内容只能是数字和字母，且字母自动转大写
        setRightForrmat(val) {
            if (val) {
                let codeReg = new RegExp("[A-Za-z0-9]+"), //正则 英文+数字；
                    len = val.length,
                    str = "";
                for (var i = 0; i < len; i++) {
                    if (codeReg.test(val[i])) {
                        str += val[i].toUpperCase();
                    }
                }
                val = str;
                return val;
            }
        },
        //判断输入内容只能是数字和字母和空格,斜杠，且字母自动转大写
        setRightForrmatSpace(val) {
            if (val) {
                let codeReg = new RegExp("[A-Za-z0-9 /]+"), //正则 英文+数字；
                    len = val.length,
                    str = "";
                for (var i = 0; i < len; i++) {
                    if (codeReg.test(val[i])) {
                        str += val[i].toUpperCase();
                    }
                }
                val = str;
                return val;
            }
        },
        setRightForrmatSpace2(val) {
            if (val) {
                let codeReg = new RegExp("[A-Za-z0-9 -.]+"), //正则 英文+数字；
                    len = val.length,
                    str = "",
                    codeReg2 = new RegExp("^(?![.]).*");
                for (var i = 0; i < len; i++) {
                    if (codeReg.test(val[i]) && codeReg2.test(val)) {
                        if (val[i] == "-" && val[i - 1] == ".") {
                            str = str.substring(0, i - 1);
                        }
                        if (val[i] == "-" && val[i - 1] == "-") {
                            str = str.substring(0, i - 1);
                        }
                        if (val[i] == "." && val[i - 1] == ".") {
                            str = str.substring(0, i - 1);
                        }
                        if (val[i] == "." && val[i - 1] == "-") {
                            str = str.substring(0, i - 1);
                        }
                        str += val[i].toUpperCase();
                    }
                }
                val = str;
                return val;
            }
        },
        setRightForrmatSpace3(val) {
            if (val) {
                let codeReg = new RegExp("[A-Za-z0-9 -/.]+"), //正则 英文+数字；
                    len = val.length,
                    str = "",
                    codeReg2 = new RegExp("^(?![.]).*");
                for (var i = 0; i < len; i++) {
                    if (codeReg.test(val[i]) && codeReg2.test(val)) {
                        if (val[i] == "/" && val[i - 1] == ".") {
                            str = str.substring(0, i - 1);
                        }
                        if (val[i] == "/" && val[i - 1] == "/") {
                            str = str.substring(0, i - 1);
                        }
                        if (val[i] == "." && val[i - 1] == ".") {
                            str = str.substring(0, i - 1);
                        }
                        if (val[i] == "." && val[i - 1] == "/") {
                            str = str.substring(0, i - 1);
                        }
                        str += val[i].toUpperCase();
                    }
                }
                val = str;
                return val;
            }
        },

        //获取全部的提单号用于搜索
        getBlnoList() {
            var that = this;
            const reqFunc = logisticsRequest({
                type: 3,
                tab: this.showTab,
            });

            reqFunc({ code: this.showTab })
                .then((response) => {
                    const data = apiCallback(response);
                    let list = data;
                    if (list !== null) {
                        list.forEach((item) => {
                            let cacheItem = {};
                            cacheItem.value = item;
                            that.totalBlnoList.push(cacheItem);
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        //获取全部的船名航次用于搜索
        getVoyageList() {
            var that = this;
            const reqFunc = logisticsRequest({
                tab: this.showTab,
                type: 2,
            });
            reqFunc({ code: this.showTab })
                .then((response) => {
                    const data = apiCallback(response);
                    let list = data;
                    if (list === null) {
                        // console.log("queryVeVo无数据");
                    } else {
                        list.forEach((item) => {
                            let cacheItem = {};
                            cacheItem.value =
                                item.vesselsName + "/" + item.voyage;
                            cacheItem.vesselsName = item.vesselsName;
                            cacheItem.voyage = item.voyage;
                            that.totalVoyageList.push(cacheItem);
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        //分页查箱单
        getBoxListByPage() {
            if (this.timeType && !this.searchtime) {
                this.$message.error("请输入搜索日期范围");
                return;
            }
            if (!this.timeType && this.searchtime) {
                this.$message.error("请输入搜索日期类别");
                return;
            }
            var that = this;
            var params = { code: this.showTab };
            const { vesseleName, voyage } = this.formatVesselenameVoyage(
                this.searchVesselenameVoyage.split("/"),
            );
            params.remark = this.searchRemark;
            params.blno = this.searchBlno;
            params.pageNum = this.pagePosition;
            params.pageSize = this.pageSize;
            params.vesselsName = vesseleName;
            params.voyage = voyage;
            params.nppStatus = this.searchNppstatus;
            params.customFlag = this.searchCustomflag;
            params.ctnApplyBeginFlag = this.searchCtnapplybeginFlag; // 开港
            params.ctnApplyEndFlag = this.searchCtnapplyendFlag; //截港
            params.portCloseFlag = this.searchPortcloseFlag; // 截单
            params.portClose = this.portcloseFlag === "1" ? 1 : undefined;
            params.ctnApplyEnd = this.portcloseFlag === "2" ? 1 : undefined;
            params.queryFlag = this.timeType;
            params.start = this.searchtime
                ? this.msToDate(this.searchtime[0]).withoutTime
                : this.searchtime;
            params.end = this.searchtime
                ? this.msToDate(this.searchtime[1]).withoutTime
                : this.searchtime;

            this.loading = true;
            this.boxList = [];
            const reqFunc = logisticsRequest({
                tab: this.showTab,
                type: 1,
            });
            reqFunc(objectFilterEmpty({ ...params }))
                .then((response) => {
                    that.loading = false;
                    const data = apiCallback(response);

                    let list = data.list;
                    if (list === null) {
                        that.totalBoxList = [];
                        that.boxList = [];
                    } else {
                        that.totalBoxList = list;

                        this.pageTotal = data.total;

                        that.startRow = data.startRow;
                        if (data.size == 0 && that.pagePosition > 1) {
                            //防止最后一页数据删除完后后页面不跳（后端返回的pageNum有问题）
                            that.pagePosition = data.pageNum - 1;
                            that.getBoxListByPage();
                        } else {
                            that.pagePosition = data.pageNum;
                        }
                    }
                    that.toggleShowList();
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        dealList(list) {
            this.boxList.splice(0, this.boxList.length);
            list.forEach((item) => {
                let marker = this.showList.findIndex(
                    (item2) => item2 == item.blno,
                );
                if (marker != -1) {
                    this.dealListTwo(item, item.sonCount);
                } else {
                    this.dealListOne(item);
                }
            });
        },
        //不展开1
        dealListOne(item) {
            var data = {};
            data.index = this.tableIndex(this.boxList.length); //序号
            data.id = item.id; //id
            data.blno = item.blno; //提单号
            data.vesselsName = item.vesselsName; //船名
            data.voyage = item.voyage; //航次
            data.remark = item.remark; //备注
            data.wharfRemark = item.wharfRemark; //码头反馈
            data.errorValue = item.errorValue; //毛重误差
            data.emptyOutCount = item.emptyOutCount; // 提空箱数量
            data.webname = item.webname; //场站
            let computedData = "-";
            if (item.inGateCount || item.nppCtnCount) {
                computedData =
                    (item.inGateCount ? `${item.inGateCount}箱 /` : "NO /") +
                    (item.nppCtnCount ? ` ${item.nppCtnCount}箱` : " NO"); //箱子进港数量
            }
            if (this.showTab === "NB") {
                data.boxArrivalCount = computedData;
            } else if (this.showTab === "SH" && computedData !== "-") {
                const preStr = item.preInGateCount
                    ? `${item.preInGateCount}箱`
                    : "NO";
                data.boxArrivalManifest = `${preStr} / ${computedData}`;
            } else if (this.showTab === "TJ") {
                data.boxArrivalManifest = item.inGateCount + "箱";
            } else {
                data.boxArrivalManifest = computedData;
            }
            data.preFlag = item.preFlag; //预配舱单标志
            data.boxCusmov = getCheck986Str(item.checkType);
            data.cpcodeFlag = item.cpcodeFlag; //码头运抵标志
            data.ifcsumFlag = item.ifcsumFlag; //清洁舱单标志
            data.sldFlag = item.sldFlag; //三联单标志
            data.nppStatus = item.nppStatus; //码头放行标志
            data.customFlag = item.customFlag; //海放标志
            data.dlPortCode = item.dlPortCode; //装卸货港
            data.wharf = item.wharf; //码头
            data.ctnOwner = item.ctnOwner; //船公司
            data.loadTime = item.loadTime; //装船时间
            data.closeTime = item.closeTime; //截关时间
            data.portCloseTime = item.portCloseTime; //截单时间
            data.ctnApplyBeginTime = item.ctnApplyBeginTime; //进箱开始时间
            data.ctnApplyEndTime = item.ctnApplyEndTime; //进箱截止时间
            data.etaArrivalTime = item.etaArrivalTime; //预计抵港时间
            data.etaDepartureTime = item.etaDepartureTime; //预计离港时间
            data.arrivalTime = item.arrivalTime; //实际抵港时间
            data.departureTime = item.departureTime; //实际离港时间
            data.direction = item.direction; //进出口标记（航向）,I-进口 E-出口
            data.topType = item.topType; // 是否置顶 1为置顶
            data.sonCount = item.sonCount; // 分票数
            data.sonList = item.sonList; // 分票
            data.psType = item.psType; // 为1则为主票
            data.createTime = item.createTime; //订阅时间
            data.changeVessels = item.changeVessels;
            data.vvFlag = item.vvFlag; // 判断是否可以修改船名航次
            data.changeLogs = item.changeLogs || []; // 上海,天津码放记录
            data.inputCtns =
                item.inputCtns && item.inputCtns != "0"
                    ? Number(item.inputCtns) + "箱"
                    : 0; // 青岛可提箱数
            data.backCtns =
                item.backCtns && item.backCtns != "0"
                    ? Number(item.backCtns) + "箱"
                    : 0; // 青岛返场箱数
            data.cargoCtns =
                item.cargoCtns && item.cargoCtns != "0"
                    ? Number(item.cargoCtns) + "箱"
                    : 0; //青岛集港箱数
            data.jgApplyBeginTime = item.jgApplyBeginTime; //青岛集港开始(开港)时间
            data.jgApplyEndTime = item.jgApplyEndTime; // 青岛集港截止时间
            data.destinationPort = item.destinationPort; //青岛目的港
            data.shipCompany = item.shipCompany; // 青岛船公司
            data.blnoMain = item.blnoMain; //青岛舱单主单号
            data.wharfCode = item.wharfCode; // 青岛云港通查询码头
            data.boxPosition = this.carNumDeal(item.truckLicense);
            if (data.topType == 1) {
                this.divlineIndex = data.index - 1;
            }
            this.boxList.push(data);
        },
        //展开
        dealListTwo(list) {
            this.dealListOne(list);
            if (list.sonList) {
                list.sonList.forEach((item, index) => {
                    var data = {};
                    if (item.blno != list.blno) {
                        data.index = this.tableIndex(this.boxList.length); //序号
                        data.id = item.id; //id
                        data.blno = item.blno; //提单号
                        data.vesselsName = item.vesselsName; //船名
                        data.voyage = item.voyage; //航次
                        data.remark = item.remark; //备注
                        data.wharfRemark = item.wharfRemark; //码头反馈
                        data.errorValue = item.errorValue; //毛重误差
                        data.emptyOutCount = item.emptyOutCount; // 提空箱数量
                        if (item.inGateCount) {
                            const computedData =
                                item.inGateCount +
                                "箱/" +
                                (item.nppCtnCount
                                    ? item.nppCtnCount + "箱"
                                    : "NO"); //箱子进港数量
                            if (this.showTab === "NB") {
                                data.boxArrivalCount = computedData;
                            } else if (["XM", "TJ"].includes(this.showTab)) {
                                data.boxArrivalManifest =
                                    item.inGateCount + "箱";
                            } else {
                                data.boxArrivalManifest = computedData;
                            }
                        }
                        data.preFlag = item.preFlag; //预配舱单标志
                        data.boxCusmov = getCheck986Str(item.checkType);
                        data.cpcodeFlag = item.cpcodeFlag; //码头运抵标志
                        data.ifcsumFlag = item.ifcsumFlag; //清洁舱单标志
                        data.sldFlag = item.sldFlag; //三联单标志
                        data.nppStatus = item.nppStatus; //码头放行标志
                        data.customFlag = item.customFlag; //海放标志
                        data.dlPortCode = item.dlPortCode; //装卸货港
                        data.wharf = item.wharf; //码头
                        data.ctnOwner = item.ctnOwner; //船公司
                        data.loadTime = item.loadTime; //装船时间
                        data.psType = item.psType; // 为1则为主票
                        data.closeTime = item.closeTime; //截关时间
                        data.portCloseTime = item.portCloseTime; //截单时间
                        data.ctnApplyBeginTime = item.ctnApplyBeginTime; //进箱开始时间
                        data.ctnApplyEndTime = item.ctnApplyEndTime; //进箱截止时间
                        data.etaArrivalTime = item.etaArrivalTime; //预计抵港时间
                        data.etaDepartureTime = item.etaDepartureTime; //预计离港时间
                        data.arrivalTime = item.arrivalTime; //实际抵港时间
                        data.departureTime = item.departureTime; //实际离港时间
                        data.direction = item.direction; //进出口标记（航向）,I-进口 E-出口
                        data.topType = item.topType; // 是否置顶 1为置顶
                        data.sonCount = item.sonCount; // 分票数
                        data.sonList = item.sonList; // 分票
                        data.createTime = item.createTime; //订阅时间
                        data.changeVessels = item.changeVessels;
                        data.changeLogs = item.changeLogs || []; // 上海码放记录
                        data.boxPosition = this.carNumDeal(item.truckLicense);
                        data.inputCtns =
                            item.inputCtns && item.inputCtns != "0"
                                ? Number(item.inputCtns) + "箱"
                                : 0; // 青岛可提箱数
                        data.backCtns =
                            item.backCtns && item.backCtns != "0"
                                ? Number(item.backCtns) + "箱"
                                : 0; // 青岛返场箱数
                        data.cargoCtns =
                            item.cargoCtns && item.cargoCtns != "0"
                                ? Number(item.cargoCtns) + "箱"
                                : 0; //青岛集港箱数
                        data.jgApplyBeginTime = item.jgApplyBeginTime; //青岛集港开始(开港)时间
                        data.jgApplyEndTime = item.jgApplyEndTime; // 青岛集港截止时间
                        data.destinationPort = item.destinationPort; //青岛目的港
                        data.shipCompany = item.shipCompany; // 青岛船公司
                        data.blnoMain = item.blnoMain; //青岛舱单主单号
                        data.wharfCode = item.wharfCode; // 青岛云港通查询码头
                        if (data.topType == 1) {
                            this.divlineIndex = data.index - 1;
                        }
                        this.boxList.push(data);
                    }
                });
            }
        },
        //集卡车号处理
        carNumDeal(truckLicense) {
            var list = [];
            if (truckLicense) {
                list = truckLicense.split(",");
            }
            return list;
        },
        // 打开上传文件组件
        uploadHandle() {
            this.uploadVisible = true;
            let p = {};
            p["type"] = "fileUrl";
            if (this.showTab === "NB") {
                this.$nextTick(() => {
                    this.$refs.upload.init();
                });
            } else if (this.showTab === "SH") {
                this.$nextTick(() => {
                    this.$refs.upload.initSH();
                });
            } else if (this.showTab === "QD") {
                this.$nextTick(() => {
                    this.$refs.upload.initQD();
                });
            } else if (this.showTab === "TJ") {
                this.$nextTick(() => {
                    this.$refs.upload.initTJ();
                });
            } else if (this.showTab === "XM") {
                this.$nextTick(() => {
                    this.$refs.upload.initXM();
                });
            }
        },
        // 搜索船司
        searchCompany(value) {
            this.optionShopCompanyList = value
                ? this.shopCompanyList.filter(
                      (item) =>
                          item.code.match(value.toUpperCase().trim()) ||
                          item.name.match(value.toUpperCase().trim()),
                  )
                : this.shopCompanyList;
        },
        //添加备注
        addRemark(item = this.rows) {
            this.remarkDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.RemarkBoxDialog.init({
                    id: item.id,
                    blno: item.blno,
                    vesselename: item.vesselename,
                    voyage: item.voyage,
                    remark: item.remark,
                });
            });
        },

        // 场站修改
        handleUpdateWebName(row) {
            this.webNameDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.WebNameDialog.init(row, this.webNameList);
            });
        },

        getData() {
            if (this.datailDialogVisible) {
                this.$nextTick(() => {
                    this.$refs.DetailDialog.getData(this.remark);
                });
            }
        },
        //添加船名航次
        addVoyage(item) {
            this.voyageDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.VoyageChangeDialog.init([item]);
            });
        },
        // 添加主提单号
        addBlnoMain(item) {
            this.blnoMainDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.BlnoMainDialog.init([item]);
            });
        },
        showCarPosition(list) {
            if (list["departureTime"]) {
                var dischargetime = new Date(list["departureTime"]).getTime();
                var nowtime = new Date().getTime();
                if (nowtime > dischargetime) {
                    this.$message.warning("已经离港不支持货车定位");
                    return;
                }
            }
            this.goTruckMap(list["id"]);
        },
        goTruckMap(id) {
            const newpage = this.$router.resolve({
                path: "/truckmap",
                query: {
                    id,
                },
            });
            window.open(newpage.href, "_blank");
        },
        goBoxQuery() {
            this.$router.push("/boxquery");
        },
        goHistory() {
            this.$router.push("/history");
        },
        goShipMap(shipInfo) {
            const newpage = this.$router.resolve({
                path: "/shipmap",
                query: {
                    blno: shipInfo.blno,
                    vesselEname: shipInfo.vesselsName,
                    voyage: shipInfo.voyage,
                },
                params: { item: shipInfo },
            });
            window.open(newpage.href, "_blank");
        },
        whetherTop(item) {
            var ids = item.id;
            // ids.push(item.id);
            if (item.topType == 0) {
                this.goTotop(ids);
            } else if (item.topType == 1) {
                this.cancelTop(ids);
            }
        },
        //置顶
        goTotop(ids) {
            var that = this;
            const reqFunc = shipRequest({
                tab: this.showTab,
                type: 3,
            });
            reqFunc([ids])
                .then((response) => {
                    that.$message.success("置顶成功");
                    that.getBoxListByPage();
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        //取消置顶
        cancelTop(ids) {
            var that = this;
            const reqFunc = shipRequest({
                tab: this.showTab,
                type: 4,
            });
            reqFunc([ids])
                .then(async (response) => {
                    that.$message.success("置顶已取消");
                    that.getBoxListByPage();
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        // 订阅成功回调
        watchSuccCallback(res) {
            const that = this;
            const data = apiCallback(res);
            if (data && data.blnos) {
                // 输入的为拼票提单号的主提单号，需要提示让用户选择
                this.$refs.childBlnoDialogRef.open(data);
            } else {
                // 正常订阅成功
                that.getBoxListByPage();
                if (!that.vesselsName && !that.voyage) {
                    that.$message({
                        type: "error",
                        duration: 3000,
                        dangerouslyUseHTMLString: true,
                        message:
                            "<div style='color:#F56C6C;font-weight:bold'><div>请注意:</div><div>尚未输入船名/航次，如只输提单号，7天内未查询<br>到任何数据，将暂停查询，需重新添加/续订才可！</div></div>",
                    });
                } else {
                    that.$message.success("订阅成功");
                }
                that.blno = "";
                that.vesselsName = "";
                that.voyage = "";
                that.putTogetherNum = "";
                that.customizeNum = "";
                that.customizeRes = [];
                that.modelOptflag = "0";
                that.chooseModelflag = "0";
                that.single_add_loading = false;
                getUserAccountInfo(true);
            }
        },
        // 订阅失败回调
        watchFailCallback(err) {
            const that = this;
            console.error(err);
            that.single_add_loading = false;
            const errMsg = err?.message;
            if (!errMsg) {
                return;
            }
            if (errMsg.indexOf("已订阅") > -1) {
                that.repeatPopVisible = true;
                that.repeatPopTitle = errMsg;
            } else if (errMsg !== "SUCCESS") {
                that.$message.error(`${errMsg}，请核对后重新添加跟踪`);
            }
        },
        //订阅箱单
        BoxesWatch(data, setting = {}) {
            if (this.showTab === "QD") {
                if (!this.webName) {
                    this.$message.warning("请选择场站");
                    return;
                }
            }
            // 暂时注释 TODO
            // if (this.showTab === "XM") {
            //     if (!this.XMWharf) {
            //         this.$message.warning("请选择码头");
            //         return;
            //     }
            // }
            this.single_add_loading = true;
            const boxesWatch = () => {
                if (data.length) {
                    var dataList = [];
                    for (var i = 0; i < data.length; i++) {
                        var List = {};
                        if (i == 0) {
                            List = {
                                blno:
                                    data[i] instanceof Object
                                        ? data[i].blno
                                        : data[i],
                                vesselsName:
                                    data[i].vesselsName || this.vesselsName,
                                voyage: data[i].voyage || this.voyage,
                                psType: "1",
                                isCheck: false,
                                companyId: getCompanyId(),
                                role: getRole(),
                            };
                            if (this.showTab === "QD") {
                                List.webname = this.webName;
                                // List.psType=setting.blnoMain
                            }
                            if (this.showTab === "XM") {
                                List.wharf = this.XMWharf;
                            }
                        } else {
                            List = {
                                blno:
                                    data[i] instanceof Object
                                        ? data[i].blno
                                        : data[i],
                                vesselsName:
                                    data[i].vesselsName || this.vesselsName,
                                voyage: data[i].voyage || this.voyage,
                                psType:
                                    data[0] instanceof Object
                                        ? data[0].blno
                                        : data[0],
                                isCheck: false,
                                companyId: getCompanyId(),
                                role: getRole(),
                            };
                            if (this.showTab === "QD") {
                                List.webname = this.webName;
                            }
                            if (this.showTab === "XM") {
                                List.wharf = this.XMWharf;
                            }
                        }
                        dataList.push({ ...List, code: this.showTab });
                    }
                }
                var that = this;
                let postUrl = "";
                let postData = {};
                let postOption = {
                    showErrorMessage: false,
                };
                if (data.length === 1) {
                    // 订阅整柜
                    postUrl = logisticsRequest({
                        tab: this.showTab,
                        type: 4,
                    });
                    postData = {
                        ...dataList[0],
                        isCheck: true,
                        companyId: getCompanyId(),
                        role: getRole(),
                    };
                } else {
                    // 订阅拼箱
                    postUrl = logisticsRequest({
                        tab: this.showTab,
                        type: 5,
                    });
                    postData = dataList;
                }
                orderBoxTicket(postUrl, postData, postOption)
                    .then(function (res) {
                        that.watchSuccCallback(res);
                    })
                    .catch((err) => {
                        that.watchFailCallback(err);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            that.repeatPopVisible = false;
                            that.$nextTick(() => {
                                that.repeatPopTitle = "";
                            });
                        }, 1500);
                        this.single_add_loading = false;
                        if (setting.fromChildDialog) {
                            // 从拼票订阅弹窗触发的订阅才需要关闭弹窗
                            this.$refs.childBlnoDialogRef.close();
                        }
                    });
            };
            const errorFun = () => {
                this.single_add_loading = false;
            };
            // 这里添加setting.fromChildDialog是因为免费余额不足弹窗会触发两次订阅
            if (
                !["TJ", "QD", "XM"].includes(this.showTab) &&
                !setting.fromChildDialog
            ) {
                freeBalanceLackTip(
                    this.showTab,
                    boxesWatch,
                    "FEE_BLNO",
                    errorFun,
                    errorFun,
                );
            } else {
                boxesWatch();
            }
        },

        // 上海拼票订阅
        handleConfirmSHSplit(data) {
            if (!data) {
                this.$message.error("请输入拼票信息");
                this.$refs.shsplitRef.open();
                return;
            }
            if (!this.blno) {
                this.$message.warning("请输入提单号");
                return;
            } else {
                let arr = data
                    .split("\n")
                    .map((item) => item.trim())
                    .filter((item) => Boolean(item));
                arr.unshift(this.blno.trim());
                this.BoxesWatch(arr);

                // else{
                //     this.BoxesWatch([this.blno.trim()],{
                //         blnoMain:data.trim(),
                //     });
                // }
            }
        },

        //下载Excel模板
        getExcelModel(id) {
            this.downloadPopVisible = false;
            // const needDownId = this.showTab === "QD" ? id + 4 : id;
            let needDownId = id;
            if (this.showTab === "QD") {
                needDownId = id + 4;
            } else if (this.showTab === "XM") {
                needDownId = id + 6;
            }
            window.open(this.$excel_url + "/" + needDownId, "_blank");
        },
        //删除单个订单
        deleteBoxItem(item) {
            let list = this.generateRecallData(item);
            this.showDeleteDialog(list);
        },
        // 生成删除订单数据
        generateRecallData(item) {
            const needDeleted = item.sonList?.length
                ? [item, ...item.sonList]
                : [item];
            return needDeleted.map(({ blno, vesselsName, voyage, id }) => ({
                vesselsName,
                blno,
                voyage,
                id,
            }));
        },
        //批量删除订单
        deleteBoxList() {
            if (this.multipleSelection.length == 0) {
                this.$message.error("请选择要删除的订单");
                return;
            }
            var list = [];
            this.multipleSelection.forEach((item) => {
                list.push(...this.generateRecallData(item));
            });
            this.showDeleteDialog(list);
        },
        //显示删除弹窗
        showDeleteDialog(list) {
            this.deleteDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.DeleteBoxDialog.init(list);
            });
        },
        //显示详情弹窗
        showDetailDialog(list) {
            this.datailDialogVisible = true;
            this.rows = list;
            this.$nextTick(() => {
                this.$refs.DetailDialog.Init(list);
            });
        },
        //显示日志弹窗
        showJournalDialog(blno) {
            this.journalDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.JournalDialog.init(blno);
            });
        },

        showSettingDialog() {
            this.settingDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.SettingDialog.init();
            });
        },
        getDate(time) {
            if (time) {
                return time.substr(5, 5);
            }
            return "-";
        },
        getDate2(time) {
            if (time) {
                var month = time.substr(4, 2);
                var day = time.substr(6, 2);
                return month + "-" + day;
            }
            return "-";
        },
        getTime(time) {
            if (time) {
                return time.substr(11, 5);
            }
            return "";
        },
        getTime2(time) {
            if (time) {
                var hour = time.substr(8, 2);
                var min = time.substr(10, 2);
                return hour + ":" + min;
            }
            return "";
        },
        // 时间处理切割
        formatTime(time) {
            return time && time !== "-" ? time.slice(5, time.length - 3) : "-";
        },
        //表格序号根据分页连续显示
        tableIndex(index) {
            return this.startRow + index;
        },
        //将数据中的字符串转为json
        getString2Json(data) {
            var json = eval("[" + data + "]")[0];
            return json;
        },
        //解决el-autocomplete 点击clearable后再输入无法显示建议列表的bug
        blurForBug() {
            document.activeElement.blur();
        },
        //设置单元格样式
        cellClass({ row, column, rowIndex, columnIndex }) {
            if (column.property == "blno") {
                let rowChangeVessels = this.boxList[rowIndex].changeVessels;
                if (rowChangeVessels == "T") {
                    return "changeTag";
                }
            }

            if (column.property) {
                //若有查验底色,若码放显示不能放行，取消放行等信息，若临近截关期或截港期，底色显示粉色
                if (
                    (column.property === "boxCusmov" && row.boxCusmov) ||
                    (column.property === "nppStatus" &&
                        row.nppStatus &&
                        row.nppStatus != "Y" &&
                        ((["QD", "SH", "TJ"].includes(this.showTab) &&
                            row.changeLogs.length > 0) ||
                            ["NB", "XM"].includes(this.showTab))) ||
                    (column.property === "closeTime" &&
                        row.closeTime &&
                        this.judgeTimeclose(
                            row.closeTime,
                            this.$store.state.ship.portCloseTime,
                            row.departureTime,
                        )) ||
                    (column.property === "portCloseTime" &&
                        row.portCloseTime &&
                        this.judgeTimeclose(
                            row.portCloseTime,
                            this.$store.state.ship.portCloseTime,
                            row.departureTime,
                        )) ||
                    (column.property === "ctnApplyEndTime" &&
                        row.ctnApplyEndTime &&
                        this.judgeTimeclose(
                            row.ctnApplyEndTime,
                            this.$store.state.ship.ctnApplyEndTime,
                            row.departureTime,
                        )) ||
                    (column.property === "errorValue" &&
                        ((this.showTab === "NB" && row.errorValue >= 5) ||
                            (this.showTab === "XM" &&
                                row.errorValue &&
                                row.errorValue != "true"))) ||
                    (column.property === "jgApplyEndTime" &&
                        row.jgApplyEndTime &&
                        this.judgeTimeclose(
                            row.jgApplyEndTime,
                            this.$store.state.ship.portCloseTime,
                            row.departureTime,
                        )) ||
                    // NB进港舱单不一致标成红色
                    (this.showTab === "NB" &&
                        column.property === "boxArrivalCount" &&
                        row.boxArrivalCount.includes("/") &&
                        row.boxArrivalCount.split("/")[0].trim() !==
                            row.boxArrivalCount.split("/")[1].trim())
                ) {
                    return "pink_cell";
                }
                if (column.property === "nppStatus") {
                    return "no-overflow-hidden";
                }
            }
        },
        cellCss({ row, rowIndex }) {
            if (
                row.topType == 1 &&
                rowIndex != this.boxList.length - 1 &&
                rowIndex == this.divlineIndex
            ) {
                return "border-bottom-color:red";
            }
        },
        //判断时间是否小于设置的时间(之前这个功能有问题，新数据是正常的)
        judgeTimeclose(time, markHour, isDepature) {
            const isDepatureValue = isDepature && isDepature !== "-";
            var date = new Date();
            var enddate = new Date(time);
            if (enddate > date) {
                return (enddate - date) / 1000 / 3600 < markHour;
            } else {
                return !isDepatureValue;
            }
        },

        selectable: function (row, index) {
            return row.sonCount;
        },

        openWindow(url) {
            window.open(url, "_blank");
        },

        // 处理拼票当前票数/总票数
        mixCount(row) {
            // 第一票A票
            if (row.sonCount) {
                return `1/${row.sonCount}`;
            }
            // 其他票
            // 主票的index
            const mainIndex = this.boxList.findIndex(
                (item) => item.blno === row.psType,
            );
            // 当前票的index
            const curIndex = this.boxList.findIndex(
                (item) => item.blno === row.blno,
            );
            if (~mainIndex && ~curIndex) {
                // 根据psType得到主票A票的sonCount
                const { sonCount = 0 } = this.boxList[mainIndex];
                // 当前票的index减去主票的index+1
                return `${curIndex - mainIndex + 1}/${sonCount}`;
            }
            return "0/0";
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
// .ad-wrap{
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 9998;
//     background: rgba(0,0,0,0.8);
// }
// .ad-image-bg{
//     background-image: url('https://web.nbhuojian.com/static/fangcangwang/1.jpeg');
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: contain;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%,-50%);
//     width: 80vw;
//     height: 80vh;
//     z-index: 9999;
// }
.changeLog-item-value{
    font-weight: bold;
}
.changeLog-item-desc{
    color: #F56C6C;
    font-size: 13px;
    margin-top: 4px;
    font-weight: bold;
}
.tab{
    width:100px;
    height:42px;
    cursor: pointer;
}
#tabber{
    width:100px;
    height:42px;
    margin-left: -7px;
    margin-right: 20px;
    cursor: pointer;
}
link-text-color-primary = #409EFF
link-text-color-primary = #409EFF
/deep/ .input_div>.el-input .el-input__inner {
  width:162px;
}

/deep/ .input_div>.el-autocomplete .el-input__inner {
  width:320px;
}
/deep/.cell{
   padding:0;
}
/deep/ .pink_cell {
  background-color: #FF98A9 !important;
}



/deep/input::-webkit-input-placeholder {
  color: #778899;
}
.tIcon_style{
    width:20px;
    height:20px;
    margin-left:10px;
}
.radio_width {
  width: 100%;

  /deep/ .el-radio__input {
    line-height: 36px;
  }
}
.model_opt_flag_wrap {
  border-left:1px solid #ccc;
  padding-left: 10px;
  .el-radio-group{
    width: 130px;
    .el-radio{
      margin-right:10px;
    }
  }
}

.search_input_div {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: 1px solid #EBEEF5;
  background: #fff;
  align-items: baseline;
  height: 32px;
  margin-bottom: 10px;

  .el-input {
    width: 15rem;
  }

  /deep/ .el-dropdown{
    font-size: 13px;
  }

  /deep/ .el-input-group__append, .el-input-group__prepend {
    border: none;
  }
}

.refresh_btn {
  height: 32px;
  padding: 4px 12px;
  cursor: pointer;
}

.repeat_pop_content {
    text-align: center;
    color: #F56C6C;
    font-size: 14px;
    font-weight: bold;
}

.watch-tooltip-message {
    font-size: 14px;
    color:#E6A23C;
    font-weight: bold;
}

.table_link_text_bold {
  color: link-text-color-primary;
  font-weight:bold;
  font-size:14px;
  cursor: pointer;
  user-select: none;
  &:hover{
    opacity: 0.8;
  }
}

.p_gold{
    color:#b87100;
}

.p_green {
  color: var(--GREEN-1E9855)
}

.p_blue{
    color:link-text-color-primary
}

.p_red {
  color: #F56C6C;
}

.el-select {
  width: 12rem;
}

p {
  text-align: center;
  margin: 0;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.input_div_father {
  display: flex;
  flex-direction: row;
  width: 98%;
  justify-content: center;
}

.input_div {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  border: none;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.title {
  color: #fff;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  user-select: none;
}

.small_title {
  line-height: 1.5;
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  user-select: none;
}

.search_div_father {

  /deep/ .el-input__inner {
    border: none;
    width: auto;
  }
}

.el-select .el-input {
  width: 13rem;
}

.query-input_center:before, .query-input_right:before , .remark-search-input:before{
  content: '';
  height: 2rem;
  margin-top: 12px;
  border-right: 1px solid #ccc;
}

.query-input_btn {
  cursor: pointer;
  height: 4rem;
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 0 4px 4px 0;
  background: #ff9807;
  border: none;
  padding:12px;
  font-weight: bold;
  &.multi{
    margin-left: 10px;
    border-radius: 4px;
    background: #409EFF;
  }
}

.data_div {
  min-height: 55vh;
  margin: 1rem 1rem 0 1rem;
  background: #fff;
  padding: 0 2rem 1rem 2rem;
}

.table_div {
  min-height: 40rem;
  /deep/.el-table__body .el-table__cell{
    &:first-child{
        .cell{
            padding-left:0
        }
    }
  }
}

.icon_wrapper{
    height:100%;
    line-height:16px;
    display: block;
}

.passIcon,.failIcon {
  height: 22px;
  width: 22px;
  margin-top:4px;
}
// @media screen and (min-width:1800px) {
//     .data_search_div{
//         justify-content: space-between;
//     }
// }
.data_search_div {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .flex_margin_item{
    // margin: 10px 0px;
  }
  /deep/ .el-button{
    height:32px;
    padding: 4px 8px;
    margin-left: 8px;
  }
  /deep/ .el-input-group {
    width: 25rem;
    // margin-left: 1rem;
  }

  /deep/ .el-input__inner {
    // border: 1px solid #DCDFE6;
    border: none;
  }

  /deep/ .el-input--suffix {
    padding: 0;
  }
}

/deep/ .el-table--border th.el-table__cell {
  color: #000 !important;
  text-align: center;
}

/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
  font-weight: 700;
}

/deep/ .el-table .cell {
  font-weight: 700;
}

/deep/ .el-table .el-table__cell {
  padding: 2px 0;
  text-align: center;
  &.changeTag{
    .cell{
        background-image: url(../../assets/img/huanicon.svg);
        background-repeat: no-repeat;
    }
  }
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #f1f3f6;
}

.el-tree {
  padding: 0 2rem 0 0;
}

.download_btn_div {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .download_btn {
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}

.pagination_div {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.table_control_div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.toTop {
  width: 40px;
  cursor: pointer;
  color: link-text-color-primary;
  user-select: none;
}

.detail {
  width: 40px;
  cursor: pointer;
  color: link-text-color-primary;
  user-select: none;
}

.journal {
  width: 40px;
  cursor: pointer;
  color: #49c23a;
  user-select: none;
}

.line {
  width: 2px;
  height: 80%;
  background: var(--GRAY-dcdcdc);
}

.top_div {
  .header_footer{
    display: flex;
    align-items: center;
    padding-top:10px;
    height: 20px;
    font-size:12px;
    color:#ED9B50;
    img {
        margin-right: 4px;
        width: 14px;
        height: 14px;
    }
    .go_boxquery{
        cursor: pointer;
        color: #8BC4F9;
    }
  }
}

.TJ_banner{
    .header_footer{
        color:white !important;
    }
}

.img_back {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.class_ship {
  width: 28px;
  height: 28px;
}

.input-together-choose {
  display: flex;
  flex-direction: row;
  width: 268px;
  textarea: 20px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.model_choose_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 38px;
  margin-top: 20px;
  border: 1px solid #EBEEF5;
  background: #fff;

  /deep/ .el-input-group__append, .el-input-group__prepend {
    border: none;
  }
}

.model_opt_btn {
  display: flex;
  margin: 0 1rem;
  font-size: 15px;
  align-items: center;
}

.span-class {
  font-family: Microsoft YaHei UI;
  color: #000;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 248px;
}



.addshow_class {
  font-size: 20px;
  float: left;
}

.reduceshow_class {
  font-size: 30px;
  float: left;
}

.together_num_class {
  font-size: 10px;
  float: right;
}

.item_his_class {
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  align-items: center;
}

/deep/.item_his_class_input .el-input input {
  border-radius: 0px;
  font-size: 15px;
}

/deep/ .is-dot .el-badge__content.is-fixed{
    top:3px;
    right:5px
}
/deep/ .is-dot .el-badge__content{
    font-size:10px;
    line-height: 1;
    padding:2px 5px;
    height:10px;
}

/deep/ .no-overflow-hidden  .cell{
    overflow:visible !important;
}

.multi_btn {
  font-size: 14px;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  border: 5px;
}

.btn_dialog_daoru {
  width: 52px;
  height: 30px;
  font-size: 12px;
}

.el-button--mini, .el-button--mini.is-round {
  padding: 0px 6px;
}

.blno-col-wrap{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .blno-left{
        img{
            // margin-top:2px;
            margin-left: 5px;
        }
    }
    .blno-center{
        img{
            margin-left:4px;
            cursor: pointer;
            &:hover{
                opacity: 0.6;
            }
        }
    }
    .blno-right{
        span{
            white-space: nowrap;
            display: inline-block;
            width: fit-content;
        }
    }
    .blno-left,.blno-center,.blno-right{
        display: flex;
        justify-content: center;
        align-items:center;
    }
}

.shipInfo-col-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
        margin-left:4px;
        margin-top: 5px;
        cursor: pointer;
        &:hover{
            opacity: 0.6;
        }
    }
}

@keyframes btnhover {
  from {
    transform: scale(1);

  }
  to {
    transform: scale(1.1)
  }
}
</style>
